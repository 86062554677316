<template>
  <div>
    <div class="row text-center">
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-3">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-link"></i><span>العقود</span>
          </div>
          <div class="overview-box-count">{{ contractsCount }}</div>
        </div>
      </div>

      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-1">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-book"></i><span>اجمالى العقود</span>
          </div>
          <div class="overview-box-count">{{ contractsTotalCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-2">
          <div class="overview-box-title">
            <i class="pi pi-tags"></i><span>اجمالي التحصيلات</span>
          </div>
          <div class="overview-box-count">{{ paymentsCount }}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-3">
        <div class=" overview-box overview-box-4">
          <div class="overview-box-title">
            <i class="pi pi-fw pi-id-card"></i><span>المتبقى</span>
          </div>
          <div class="overview-box-count">{{ subCount }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-5" v-if="show">
      <div class="col-md-4">
        <Chart type="doughnut" :data="chartData" />
      </div>
      <div class="col-md-8">
        <Chart
          type="bar"
          :data="basicData"
          :options="{
            plugins: {
              title: {
                display: true,
                text: 'معدل انحرف العقود',
              },
              tooltips: {
                mode: 'index',
                intersect: false,
              },
              legend: {
                labels: {
                  color: '#495057',
                },
              },
            },
            scales: {
              x: {
                stacked: true,
                ticks: {
                  color: '#495057',
                },
                grid: {
                  color: '#ebedef',
                },
              },
              y: {
                stacked: true,
                ticks: {
                  color: '#495057',
                },
                grid: {
                  color: '#ebedef',
                },
              },
            },
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contractsCount: 0,
      subCount: 0,
      contractsTotalCount: 0,
      paymentsCount: 0,

      chartData: {
        labels: ['اجمالى العقود', 'المحصل', 'المتبقي'],
        datasets: [
          {
            data: [300, 50, 0],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          },
        ],
      },
      basicData: {
        labels: [],

        datasets: [
          {
            label: 'قبل واقت التسليم',
            data: [1, 2, 2, 1, 1, 0, 3, 5, 7, 8, 6, 5],
            backgroundColor: '#42A5F5',
            type: 'bar',
          },
          {
            label: 'بعد واقت التسليم',
            data: [-2, -1, -4, -1, -6, -2, -9, -8, -6, -5, -4, -6],
            backgroundColor: '#FFA726',
            type: 'bar',
          },
        ],
      },
      show: false,
    };
  },

  methods: {
    getData() {},
  },
  async created() {
    const user = JSON.parse(localStorage.user);

    const contracts = await this.$http.post(`contracts/count`, {
      clientsId: user.id,
    });

    this.contractsCount = await contracts.data;

    const contractsTotal = await this.$http.get(
      `contracts/contractsTotalCountByClientsId/${user.id}`,
    );

    this.contractsTotalCount = await contractsTotal.data.reduce((val, item) => {
      return val + item.total;
    }, 0);

    const payments = await this.$http.get(
      `contracts/contractsTotalPayCountByClientsId/${user.id}`,
    );

    this.paymentsCount = await payments.data.reduce((val, item) => {
      return val + item.price;
    }, 0);

    this.subCount = this.contractsTotalCount - this.paymentsCount;

    this.chartData.datasets[0].data = await [
      this.contractsTotalCount,
      this.paymentsCount,
      this.contractsTotalCount - this.paymentsCount,
    ];

    const getContractsByDeliveryDate = await this.$http.get(
      `contracts/getContractsByDeliveryDateByCId/${user.id}`,
    );

    this.basicData.labels = await getContractsByDeliveryDate.data.map(
      (el) => el.code,
    );
    this.basicData.datasets[0].data = await [];
    this.basicData.datasets[1].data = await [];
    for (const item of getContractsByDeliveryDate.data) {
      if (item.days == 0) {
        this.basicData.datasets[0].data.push(0);
        this.basicData.datasets[1].data.push(0);
      }
      if (item.days > 0) {
        this.basicData.datasets[0].data.push(item.days);
        this.basicData.datasets[1].data.push(0);
      }

      if (item.days < 0) {
        this.basicData.datasets[0].data.push(0);
        this.basicData.datasets[1].data.push(item.days);
      }
    }

    // const clientsTotalCountMonth = await this.$http.get(
    //   `clients/clientsTotalCountMonth`,
    // );

    this.show = await true;
  },
};
</script>

<style>
.overview-box {
  padding: 15px;
  color: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  margin: 0 !important;
}
.overview-box .overview-box-title {
  font-weight: bold;
  width: 100%;
}
.overview-box .overview-box-title i {
  vertical-align: middle;
  font-size: 20px;
}
.overview-box .overview-box-title span {
  margin-right: 0.5em;
  vertical-align: middle;
}
.overview-box .overview-box-count {
  color: #ffffff;
  font-size: 24px;
  width: 100%;
  display: block;
  padding: 5px 0;
}
.overview-box.overview-box-1 {
  background-color: #007bff;
  border: solid 1px #007bff;
  color: #ffffff;
}
.overview-box.overview-box-2 {
  background-color: #28a745;
  border: solid 1px #28a745;
  color: #ffffff;
}
.overview-box.overview-box-3 {
  background-color: #fd7e14;
  border: solid 1px #fd7e14;
  color: #ffffff;
}
.overview-box.overview-box-4 {
  background-color: #6f42c1;
  border: solid 1px #6f42c1;
  color: #ffffff;
}
</style>
